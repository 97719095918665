import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import { Container, Row, Col, Image } from "react-bootstrap"

import fourbyfour from "../images/woven-wire-cloth/4x4-mesh-250.png"
import twobytwo from "../images/woven-wire-cloth/2x2-mesh-250.png"

import oneMesh from "../images/woven-wire-cloth/one-mesh-120.png"

// import BackgroundImage from 'gatsby-background-image'

const pageInfo = () => (
  <Layout>
    <SEO title="Woven Wire Cloth" />
    <Container>
      <h1>Woven Wire Cloth</h1>
      <p>Howard Wire Cloth Company Stocks a Wide Variety of Woven Wire Cloth in Our Hayward, California Facilities.</p>
      <p>Below you will find some of our available options:</p>

      <Row>
        <Col lg={6}>
          <Image src={fourbyfour} alt="Wire Mesh" fluid />
        </Col>
        <Col lg={6}>
          <h2>Available Alloys Commonly Stocked</h2>
          <ul>
            <li>Aluminum Woven Wire Cloth</li>
            <li>Brass Woven Wire Cloth</li>
            <li>Copper Woven Wire Cloth</li>
            <li>Galvanized Woven Wire Cloth</li>
            <li>Plain Steel Woven Wire Cloth</li>
            <li>Stainless Steel 304/316 Woven Wire Cloth</li>
          </ul>
        </Col>
      </Row>

      <hr />

      <Row>
        <Col lg={6}>
          <Image src={twobytwo} alt="Wire Mesh" fluid />
        </Col>
        <Col lg={6}>
          <h2>Specialty Alloys for Custom Orders</h2>
          <ul>
            <li>Carpenter 20 Wire Cloth</li>
            <li>Hastelloy Wire Cloth</li>
            <li>Inconel Wire Cloth</li>
            <li>Monel Wire Cloth</li>
            <li>Nichrome Wire Cloth</li>
            <li>Nickel Wire Cloth</li>
          </ul>
        </Col>
      </Row>

      <hr />

      <Row>
        <Col lg={6}>
          <Image src={oneMesh} alt="Wire Mesh" fluid />
        </Col>
        <Col lg={6}>
          <h2>Types of Weaves Commonly Stocked</h2>
          <ul>
            <li>Double Crimp</li>
            <li>Double/Intermediate Crimp</li>
            <li>Intermediate Crimp</li>
            <li>Flat Top Crimp</li>
            <li>Lock Crimp</li>
            <li>Plain Weave</li>
            <li>Twilled Dutch Weave</li>
            <li>Twilled Weave</li>
            <li>Plain Dutch Weave</li>
          </ul>
        </Col>
      </Row>

      <hr />

      <p>
        <strong>Available Mesh Sizes Commonly Stocked:</strong><br />
        (clear openings or center-to-center)</p>

        <p>4″ x 4″ all the way down to 200 mesh (200 openings per lineal inch)</p>
        
        <p><strong>Available Wire Diameters Commonly Stocked:</strong><br />
        .250″ all the down to .0021″
        </p>


  </Container>
</Layout>
)

export default pageInfo
